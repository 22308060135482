.prize-title {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  color: #fff;
  line-height: 2 !important;
}

.prize-value {
  background: #f1ea3e;
  width: fit-content;
  padding: 10px;

  //   clip-path: polygon(
  //     10px 0px,
  //     100% 0%,
  //     calc(100% - 20px) 50%,
  //     100% 100%,
  //     0% 100%
  //   );
}
