$text-color: #fff;

.hg-title {
  color: $text-color;
  font-size: 30px !important;
}
.hg-heading {
  color: $text-color;
  overflow-wrap: break-word;
  font-family: "Montserrat";
  font-weight: 800 !important;
  margin-bottom: 40px;
}
.hg-images {
  text-align: center;
  // padding: 20px;
}

.player-name {
  text-transform: lowercase;
}
.player-name::first-letter {
  text-transform: uppercase;
}
