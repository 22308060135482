.about-container {
  display: flex;
  // align-items: flex-end;

  .about-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    color: #e42722;
    // line-height: 64px; /* 114.286% */
    letter-spacing: -1px;
    padding-bottom: 20px;
  }
  .about-desc {
    font-family: "Montserrat", sans-serif;
    color: #000c16;
    // font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }
}
