.social-name {
  font-family: "Montserrat", sans-serif;
  padding: 12px 0;
}
.social-icons {
  margin-right: 15px;
}
.contact-us {
  display: flex;
  align-items: center;
}
.contact-detail {
  // font-weight: 600;
  font-family: "Montserrat", sans-serif;
  // font-size: 22px;
}
.footer-border {
  background-color: #d4d4d4;
  width: 90%;
  height: 1px;
  border: 0px solid #d4d4d4;
  // margin-top: 30px;
}
.footer-copyright {
  font-family: "Montserrat", sans-serif;
  // font-size: 12px;
}
