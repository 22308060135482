.home-container {
  //   background-image: url("https://firebasestorage.googleapis.com/v0/b/kplcricket-d5078.appspot.com/o/hero_baner.png?alt=media&token=2794f782-fc7b-4997-b3dc-69a44cb81f41");
  // height: 800px;
  background-repeat: no-repeat;
  // background-size: contain;

  .home-logo {
    width: 15%;
    margin-top: 10%;
  }

  .home-logo-mobile {
    width: 50%;
    margin-top: 10%;
  }
  .home-logo-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dislodged-border {
    background: yellow;
    color: #000;
    display: inline-block;
    font-family: sans-serif;
    padding: 15px 20px 14px;
    position: relative;
    text-decoration: none;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
  }

  .dislodged-border,
  .dislodged-border:before {
    box-sizing: border-box;
  }

  .dislodged-border:before {
    border: 2px solid #fff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 7px;
    left: 7px;
    z-index: -1;
  }
}
