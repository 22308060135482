$text-color: #fff;
.home-gallery-container {
  background-color: #000;

  .hg-title {
    color: $text-color;
  }
  .hg-heading {
    color: $text-color;
    overflow-wrap: break-word;
    font-family: "Montserrat";
    font-weight: 800;
    // font-size: 48px;
  }
  .hg-images {
    text-align: center;
    // padding: 20px;
  }
}
