.dataGridContainer {
  padding: 0 30px;
  background-color: #fff;
  h6 {
    color: #cc0000;
  }
  table th {
    // table-layout: fixed;
    border: 1px solid;
    // font-family: "Montserrat";
    color: #cc0000;
  }
  table,
  thead th span {
    padding: 0 10px;
    justify-content: left;
    font-family: "Montserrat";
  }
  table tbody td {
    border: 1px solid;
    padding: 0 10px;
    text-align: left;
  }
  table thead tr th span button {
    font-size: 16px !important;
  }
}
