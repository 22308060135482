.nav-logo {
  margin: 6px 0;
  width: 7%;
}
.nav-bar {
  background-color: transparent !important;
  box-shadow: none !important;
}
%nav-link {
  text-decoration: none;
  margin-right: 40px;
  font-size: 20px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
}
.nav-link-mobile {
  @extend %nav-link;
  width: 100%;
}

.nav-link-desktop {
  @extend %nav-link;
  color: #fff;
}

.dislodged-border {
  background: yellow;
  color: #000;
  display: inline-block;
  font-family: sans-serif;
  padding: 15px 20px 14px;
  position: relative;
  text-decoration: none;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}

.dislodged-border,
.dislodged-border:before {
  box-sizing: border-box;
}

.dislodged-border:before {
  border: 2px solid #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 7px;
  left: 7px;
  z-index: -1;
}
